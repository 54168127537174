import React from 'react';
import PropTypes from 'prop-types';
import Helmet from '../project/Helmet';
import withDataHOC from '../dataProvider/withDataHOC';
import locationHOC from '../locationProvider/locationHOC';
import {createSetQueryParamValue, getQueryParam, getQueryParamNumber} from '../../lib/url';
import {GLOBAL_DATA} from '../../constants/globalData';
import {QUERY_PARAMS, ROUTES} from '../../constants/navigation';
import {Trans, t} from '@lingui/macro';
import { RIGHTS } from '../../constants/Rights';
import EmployeesSelect from '../project/EmployeesSelect';
import DashboardItemDisplay from './DashboardItemDisplay';
import RightsWrapper from '../sessionProvider/RightsWrapper';
import NavigateButton from '../general/NavigateButton';
import DashboardAssignments from './DashboardAssignments';
import pinProtectedPageHOC from '../fetch/pinProtectedPageHOC';
import { currentMonth } from '../../lib/date';
import InputText from '../general/InputText';

/**
 * @dusan
 */

class DashboardPage extends React.PureComponent {
    static propTypes = {
        pin: PropTypes.string.isRequired,
        onPinFailure: PropTypes.func.isRequired,
        [GLOBAL_DATA.DASHBOARD_ITEMS]: PropTypes.array.isRequired,
    };

    getUserId = (props) => {
        const {location, [GLOBAL_DATA.ACCOUNT_INFO]: account} = props;
        const userId = getQueryParamNumber(location, QUERY_PARAMS.DASHBOARD_ID_USER) || account.id;
        return userId;
    }


    render() {
        const {location, [GLOBAL_DATA.DASHBOARD_ITEMS]: items, pin, onPinFailure} = this.props;
        const at = getQueryParam(location, QUERY_PARAMS.DASHBOARD_AT) || currentMonth();
        const dt = new Date(at);
        const year = dt.getFullYear();
        const month = dt.getMonth() + 1;
        const userId = this.getUserId(this.props);

        return <React.Fragment>
            <Helmet
                title={t`Štatistiky`}
            />
            <div className="full-size-height overflow-y-auto">
                <div className="d-flex m-3">
                    <div className="d-flex align-items-center">
                        <span className="mr-2"><Trans>Mesiac:</Trans></span>
                        <InputText
                            type="month"
                            className="dashboard-month-select"
                            value={at}
                            changeImmediately={true}
                            onChange={createSetQueryParamValue(location, QUERY_PARAMS.DASHBOARD_AT)}
                        />
                    </div>
                    <RightsWrapper from={RIGHTS.MANAGER}>
                        <div className="d-flex align-items-center">
                            <span className="ml-3 mr-2"><Trans>Zamestnanec:</Trans></span>
                            <EmployeesSelect
                                className="dashboard-user-select"
                                value={userId}
                                onChange={createSetQueryParamValue(location, QUERY_PARAMS.DASHBOARD_ID_USER)}
                            />
                        </div>
                    </RightsWrapper>
                    <NavigateButton
                        to={ROUTES.ASSIGNMENTS}
                        queryParams={{
                            [QUERY_PARAMS.ASSIGNMENTS_ID_USER]: userId,
                            [QUERY_PARAMS.ASSIGNMENTS_YEAR]: year,
                        }}
                    >
                        <Trans>Zobraziť úlohy a odmeny</Trans>
                    </NavigateButton>
                </div>
                <div className="p-3 d-flex flex-wrap">
                    { items.length > 0 ? 
                        items.map((it) => {
                            return <DashboardItemDisplay
                                key={it.name}
                                name={it.name}
                                label={it.label}
                                pin={pin}
                                userId={userId}
                                at={at}
                                onPinFailure={onPinFailure}
                            />
                        }) :
                        <h1 className="d-inline color-disabled">
                            <Trans>Nie sú dostupné žiadne štatistiky.</Trans>
                        </h1>
                    }
                    <DashboardAssignments
                        userId={userId}
                        year={year}
                        month={month}
                        pin={pin}
                    />
                </div>
            </div>
        </React.Fragment>;
    }

}

export default pinProtectedPageHOC(
    locationHOC(
        withDataHOC([
            GLOBAL_DATA.DASHBOARD_ITEMS, 
            GLOBAL_DATA.ACCOUNT_INFO
        ])(DashboardPage)
    )
);